import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_menu = _resolveComponent("md-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flow-menu",
    style: _normalizeStyle({'top': _ctx.y + 'px', 'left': _ctx.x + 'px'})
  }, [
    _createVNode(_component_md_menu, {
      variant: "flat",
      items: _ctx.items,
      small: "",
      onSelect: _ctx.onSelect
    }, null, 8, ["items", "onSelect"])
  ], 4))
}