
import { defineComponent } from 'vue';
import { MdInputField } from '@/components/md/MdInput';
import { MdButton } from '@/components/md/MdButton';
import { MdOverlayContentMixin } from "@/components/md/MdOverlay";
import { useStore } from '@/store';
import { publishVersion } from "@/api/bot/versions";
import { BotStore, RootStore } from '@/store/constants';

export default defineComponent({
  name: "VersionPublishOverlay",
  mixins: [MdOverlayContentMixin],
  components: {
    MdInputField,
    MdButton,
  },
  data() {
    return {
      name: "",
      description: ""
    }
  },
  setup() {
    return {
      store: useStore()
    }
  },
  methods: {
    async publishVersion() {
      this.$emit("loading", true);
      try {
        await publishVersion(this.name, this.description);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Version wird bereitgestellt..."
        });
        this.store.dispatch(BotStore.Actions.LOAD_VERSIONS);
        this.$emit("complete");
        this.$emit("close");
      } catch(err) {
        console.error("error: ", err);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Version konnte nicht veröffentlicht werden"
        });
        this.$emit("close");
      }
      this.$emit("loading", false);
    }
  },
})
