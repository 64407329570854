
import { defineComponent, PropType } from "vue";
import FlowPanelElementWrapper from "@/components/bot/FlowPanelElementWrapper.vue";
import { MdButton } from "@/components/md/MdButton";
import { MdCard } from "@/components/md/MdCard";
import { MdContentEditable } from "@/components/md/MdInput";
import { MdTabsInline, MdTab } from "@/components/md/MdTabs";
import { IButtonsElement, ILanguageValue } from "@chatcaptain/types/chatbot";
import { useStore } from "@/store";
import { BotStore } from "@/store/constants";
import { VueDraggableNext } from "vue-draggable-next";
import { InputActionsWrapper } from "@/components/cc/InputActions";

export default defineComponent({
  name: "FlowPanelElementButtons",
  components: {
    VueDraggableNext,
    FlowPanelElementWrapper,
    MdButton,
    MdCard,
    MdContentEditable,
    MdTabsInline,
    MdTab,
    InputActionsWrapper
  },
  setup() {
    return {
      store: useStore()
    }
  },
  data() {
    return {
      content: "",
      text: "",
      focus: false,
      target: {} as HTMLElement,
      type: "block",
      element: {} as IButtonsElement,
      buttons: [] as IButtonsElement["content"],
      showEditor: false,
      editorY: 0,
      targetButton: {} as IButtonsElement["content"][0],
      targetButtonIndex: 0,
      targetButtonUrl: ""
    }
  },
  emits: ["update:modelValue", "overlay"],
  props: {
    modelValue: Object as PropType<IButtonsElement>
  },
  computed: {
    language(): string {
      return this.store.getters[BotStore.Getters.GET_LANGUAGE];
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value: IButtonsElement) {
        this.element = value;
        this.buttons = [];
        value.content.forEach(button => {
          this.buttons.push({
            ...button,
            name: typeof button.name == "string" ? {[this.store.getters[BotStore.Getters.GET_DEFAULT_LANGUAGE]]: button.name} : button.name
          });
        });
      }
    },
    buttons: {
      deep: true,
      handler(val) {
        this.element.content = this.buttons;
        this.$emit("update:modelValue", this.element);
      }
    }
  },
  mounted() {
    if(this.$refs && this.$refs.input) this.target = document.getElementById((this.$refs.input as {id: string}).id) as HTMLElement;
  },
  methods: {
    openEditor(event: PointerEvent, button: IButtonsElement["content"][0], index: number) {
      this.showEditor = true;
      this.$emit("overlay", true);
      const element = (event.target as HTMLElement).offsetParent;
      const target = element as HTMLElement;
      this.editorY = target.offsetTop;
      this.targetButton = JSON.parse(JSON.stringify(button)) as IButtonsElement["content"][0];
      this.targetButtonIndex = index;
      this.targetButtonUrl = "";
      if(this.targetButton.type == "url") this.targetButtonUrl = this.targetButton.value;
    },
    closeEditor() {
      this.$emit("overlay", false);
      this.showEditor = false;
    },
    saveButton() {
      this.buttons[this.targetButtonIndex] = this.targetButton;
      if(this.targetButton.type == "url") this.targetButton.value = this.targetButtonUrl;
      this.buttons[this.targetButtonIndex] = this.targetButton;
      this.closeEditor();
    },
    addButton() {
      this.buttons.push({
        name: {
          [this.language]: "Unbenannter Button",
          [this.store.getters[BotStore.Getters.GET_DEFAULT_LANGUAGE]]: "Unbenannter Button"
        } as ILanguageValue<string>,
        value: "",
        type: "block"
      });
    }
  },
});
