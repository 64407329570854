
import { defineComponent } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { BotStore, RootStore } from "@/store/constants";
import { addLanguage, deleteLanguage, getChatbotId } from "@/api/bot/language";

export default defineComponent({
  name: "SettingsLanguagesDeleteModal",
  mixins: [MdModalComponentMixin],
  components: { 
    MdModal,
    MdButton
  },
  props: {
    language: String
  },
  setup() {
    getChatbotId();
    const store = useStore();
    return { store };
  },
  computed: {
    name(): string {
      return this.store.getters[BotStore.Getters.GET_LANGUAGE_NAME](this.language || "") || "Unbekannt";
    }
  },
  methods: {
    async deleteLanguageSubmit() {
      try {
        this.setLoadingState(true);
        if(this.language) await deleteLanguage(this.language);
        this.setLoadingState(false);
        this.close();
        this.store.dispatch(BotStore.Actions.LOAD_LANGUAGES);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Sprache gelöscht"
        });
      } catch(err) {
        console.error("error: ", err);
        this.setLoadingState(false);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Sprache konnte nicht gelöscht werden"
        });
        this.close();
      }
    }
  },
});
