
import { defineComponent, PropType } from "vue";
import { MdCard } from "@/components/md/MdCard";
import { MdButton } from "@/components/md/MdButton";
import { MdTooltip } from "@/components/md/MdTooltip";

export default defineComponent({
  name: "FlowPanelElementWrapper",
  components: {
    MdCard,
    MdButton,
    MdTooltip
  },
  props: {
    icon: String,
    name: String
  },
  emits: ["delete"],
  data() {
    return {
      focused: false,
      hoverTimeout: null as null | NodeJS.Timeout
    }
  },
  methods: {
    onHover(mouseEnter: boolean){
      if(mouseEnter){
        this.focused = true;
        if(this.hoverTimeout) clearTimeout(this.hoverTimeout);
      } else {
        this.hoverTimeout = setTimeout(() => {
          this.focused = false;
        }, 800);
      }
    }
  },
});
