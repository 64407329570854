
import { defineComponent } from "vue";

export default defineComponent({
  name: "ViewHeader",
  props: {
    title: String,
    description: String
  }
});
