
import { defineComponent, PropType } from "vue";
import FlowPanelElementWrapper from "@/components/bot/FlowPanelElementWrapper.vue";
import { MdContentEditable } from "@/components/md/MdInput";
import { InputActionsWrapper } from "@/components/cc/InputActions";
import { ILanguageValue, ITextElement } from "@chatcaptain/types/chatbot";
import { useStore } from "@/store";
import { BotStore } from "@/store/constants";

export default defineComponent({
  name: "FlowPanelElementText",
  components: {
    FlowPanelElementWrapper,
    MdContentEditable,
    InputActionsWrapper
  },
  props: {
    modelValue: Object as PropType<ITextElement>
  },
  setup() {
    return {
      store: useStore()
    };
  },
  emits: ["update:modelValue"],
  data() {
    return {
      element: {} as ITextElement,
      message: {} as ILanguageValue<string>,
      content: "",
      text: "",
      target: {} as HTMLElement
    }
  },
  computed: {
    language(): string {
      return this.store.getters[BotStore.Getters.GET_LANGUAGE];
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler(value: ITextElement) {
        this.element = value;
        const msg = this.element.content.message;
        this.message = typeof msg == "string" ? {[this.store.getters[BotStore.Getters.GET_DEFAULT_LANGUAGE]]: msg} : msg;
      }
    },
    content() {
      this.element.content.message = this.message;
      this.$emit("update:modelValue", this.element);
    }
  },
  mounted() {
    if(this.$refs && this.$refs.input) this.target = document.getElementById((this.$refs.input as {id: string}).id) as HTMLElement;
  },
});
