
import { defineComponent } from "vue";
import { IRow, IRowBadge, IRowButton, MdTable, MdTableColumn } from "@/components/md/MdTable";
import { MdButton } from "@/components/md/MdButton";
import { ViewHeader } from "@/components/cc/ViewHeader";
import SettingsLanguagesAddModal from "@/components/bot/SettingsLanguagesAddModal.vue";
import SettingsLanguagesDeleteModal from "@/components/bot/SettingsLanguagesDeleteModal.vue";
import { IMdModal } from "@/components/md/MdDialog";
import { useStore } from "@/store";
import { BotStore } from "@/store/constants";

export default defineComponent({
  name: "BotSettingsLanguages",
  components: {
    MdTable,
    MdTableColumn,
    ViewHeader,
    MdButton,
    SettingsLanguagesAddModal,
    SettingsLanguagesDeleteModal
  },
  setup() {
    const store = useStore();
    store.dispatch(BotStore.Actions.LOAD_LANGUAGES);
    return { store }
  },
  data() {
    return {
      deleteButton: {
        icon: "delete",
        tooltip: "Löschen"
      } as IRowButton,
      targetLanguage: ""
    }
  },
  computed: {
    addLanguageModal(): IMdModal {
      return this.$refs.addLanguageModal as IMdModal;
    },
    deleteLanguageModal(): IMdModal {
      return this.$refs.deleteLanguageModal as IMdModal;
    },
    languages(): string[] {
      return this.store.getters[BotStore.Getters.GET_LANGUAGES];
    },
    rows(): IRow[] {
      const rows: IRow[] = [];
      this.languages.forEach(lang => {
        rows.push({
          name: this.store.getters[BotStore.Getters.GET_LANGUAGE_NAME](lang),
          code: lang,
          default: (this.store.getters[BotStore.Getters.GET_DEFAULT_LANGUAGE] == lang) ?
          {
            state: "info",
            text: "Standard"
          } as IRowBadge : null,
          delete: this.deleteButton
        });
      });
      return rows;
    }
  },
  methods: {
    tableButtonClick(columnName: string, row: IRow, index: number) {
      this.targetLanguage = row.code;
      this.deleteLanguageModal.open();
    }
  },
});
