
import { defineComponent } from "vue";
import { IRow, IRowBadge, IRowButton, MdTable, MdTableColumn } from "@/components/md/MdTable";
import { useStore } from "@/store";
import { BotStore } from "@/store/constants";
import { IModel, IVersion } from "@chatcaptain/types/chatbot";
import { EmptyHint } from "@/components/cc/EmptyHint";
import { MdButton } from "@/components/md/MdButton";
import { MdFabExtended } from "@/components/md/MdFab";
import { MdOverlay } from "@/components/md/MdOverlay";
import { MdCard } from "@/components/md/MdCard";
import { MdProgressIndicatorCircular } from "@/components/md/MdProgressIndicator";
import VersionPublishOverlay from "@/components/bot/VersionPublishOverlay.vue";
import PublishVersionImage from "@/assets/images/overlay-invite-user.svg";

export default defineComponent({
  name: "Versions",
  components: {
    MdTable,
    MdTableColumn,
    EmptyHint,
    MdButton,
    MdFabExtended,
    MdOverlay,
    MdCard,
    MdProgressIndicatorCircular,
    VersionPublishOverlay
  },
  setup() {
    return {
      store: useStore()
    };
  },
  data() {
    return {
      publishVersionVisible: false,
      publishVersionLoading: false,
      PublishVersionImage,
      isLoading: true,
      deleteButton: {
        icon: "delete",
        tooltip: "Löschen",
      } as IRowButton,
      publishButton: {
        icon: "publish",
        tooltip: "Veröffentlichen",
      } as IRowButton,
      liveBadge: {
        state: "success",
        text: "Live",
      } as IRowBadge
    }
  },
  mounted() {
    this.store.dispatch(BotStore.Actions.LOAD_VERSIONS);
    this.store.dispatch(BotStore.Actions.LOAD_LIVE_VERSION);
    this.store.dispatch(BotStore.Actions.LOAD_LAST_VERSION);
    this.store.dispatch(BotStore.Actions.LOAD_MODELS);
    this.openPublishNewVersionByQuery();
  },
  watch: {
    versions() {
      this.isLoading = false;
    },
    '$route.query'() {
      this.openPublishNewVersionByQuery();
    }
  },
  computed: {
    versions(): IVersion[] {
      return this.store.getters[BotStore.Getters.GET_VERSIONS];
    },
    liveVersion(): number {
      return this.store.getters[BotStore.Getters.GET_LIVE_VERSION];
    },
    lastVersion(): IVersion | null {
      return this.store.getters[BotStore.Getters.GET_LAST_VERSION];
    },
    models(): IModel[] {
      return this.store.getters[BotStore.Getters.GET_MODELS];
    },
    isLastVersionPublished(): boolean {
      return !!this.lastVersion && Object.values(this.lastVersion?.models || {}).every(model => model == 'published');
    },
    rows(): IRow[] {
      const rows: IRow[] = [];
      this.versions.forEach((version, index) => {
        const isLive = version.id == this.liveVersion;
        const isLast = index == 0;
        rows.push({
          id: version.id,
          state: isLive ? this.liveBadge : null,
          name: version.name,
          created: this.getFormattedDate(version.created.toDate()) + " um " + this.getFormattedTime(version.created.toDate()) + " Uhr",
          publish: !isLive && !isLast ? this.publishButton : null,
          delete: !isLive && !isLast ? this.deleteButton : null,
        });
      });
      return rows;
    }
  },
  methods: {
    openPublishNewVersionByQuery() {
      if (this.$route.query.publish == "1") {
        this.publishVersionVisible = true;
        this.$router.replace({query: {}});
      }
    },
    getFormattedDate(date: Date): string {
      return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
    },
    getFormattedTime(date: Date): string {
      return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
    },
    publishingStarted() {
      this.store.dispatch(BotStore.Actions.LOAD_LIVE_VERSION);
    },
    getModelName(modelId: string): string {
      return this.store.getters[BotStore.Getters.GET_LANGUAGE_NAME](this.models.find(model => model.id == modelId)?.language || "") || "Ubekannt";
    }
  },
});
