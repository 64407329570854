import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16052d99"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "material-icons-round"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", {
    class: _normalizeClass(["md-hint", { icon: _ctx.icon }])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("i", _hoisted_1, _toDisplayString(_ctx.icon), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}