
import { defineComponent } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { IMenuItem } from "../md/MdMenu";
import { BotStore, RootStore } from "@/store/constants";
import { MdInputField, MdSelect } from "@/components/md/MdInput";
import { InputType, InputValidator } from "@/classes/InputValidator";
import { addLanguage, getChatbotId } from "@/api/bot/language";
import { MdCheckbox } from "@/components/md/MdCheckbox";
import { MdAlert } from "@/components/md/MdAlert";

export default defineComponent({
  name: "SettingsLanguagesAddModal",
  mixins: [MdModalComponentMixin],
  components: { 
    MdModal,
    MdButton,
    MdSelect,
    MdCheckbox,
    MdAlert
  },
  data() {
    return {
      availableLanguages: ["DE", "EN", "DA", "SV", "ES", "FR", "IT"],
      language: "",
      translateExamples: false,
      translateContent: false
    }
  },
  setup() {
    getChatbotId();
    const store = useStore();
    return { store };
  },
  computed: {
    items(): IMenuItem[] {
      const items: IMenuItem[] = [];
      const addedLanguages = this.store.getters[BotStore.Getters.GET_LANGUAGES];
      this.availableLanguages.forEach(lang => {
        if(!addedLanguages.includes(lang)){
          items.push({
            label: this.store.getters[BotStore.Getters.GET_LANGUAGE_NAME](lang),
            value: lang
          });
        }
      });
      return items;
    },
    languageInput(): typeof MdInputField {
      return this.$refs.languageInput as typeof MdInputField;
    },
    languageValidator(): InputValidator {
      return new InputValidator(this.language, InputType.TEXT, true);
    },
    defaultLanguageName(): string {
      return this.store.getters[BotStore.Getters.GET_LANGUAGE_NAME](this.store.getters[BotStore.Getters.GET_DEFAULT_LANGUAGE]);
    }
  },
  methods: {
    async addLanguageSubmit() {
      this.languageInput.validate();
      if(!this.languageValidator.isValid()) return;
      this.setLoadingState(true);
      await addLanguage(this.language, this.translateExamples, this.translateContent);
      this.setLoadingState(false);
      this.close();
      this.language = "";
      this.store.dispatch(BotStore.Actions.LOAD_LANGUAGES);
      this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        state: "success",
        content: "Sprache hinzugefügt"
      });
    }
  },
});
