
import { defineComponent, PropType } from "vue";
import { IBlock, ISplit } from "@chatcaptain/types/chatbot";
import FlowPanelContentMixin, { ISaveParams } from "@/components/bot/FlowPanelContentMixin";
import { Block } from "@/classes/Block";
import { MdInputField } from "@/components/md/MdInput";
import { MdHint } from "@/components/md/MdHint";
import { MdAlert } from "@/components/md/MdAlert";
import { MdRadio } from "@/components/md/MdRadio";
import { useStore } from "@/store";

export default defineComponent({
  name: "FlowPanelContentSplit",
  mixins: [FlowPanelContentMixin],
  components: {
    MdInputField,
    MdHint,
    MdAlert,
    MdRadio
  },
  props: {
    data: {
      type: Object as PropType<IBlock>,
      required: true
    }
  },
  setup() {
    return {
      store: useStore()
    }
  },
  data() {
    return {
      block: {} as ISplit,
      index: 0,
      exitA: 50,
      exitB: 50,
      sameRedirect: "on"
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(val: ISplit) {
        this.block = val;
        this.sameRedirect = val.same_redirect ? "on" : "off"
        if(val.split){
          this.exitA = val.split.A * 100;
          this.exitB = val.split.B * 100;
        }
      }
    },
  },
  methods: {
    async save(flowId: string, params?: ISaveParams) {
      if(!this.block.id) return;
      const block = new Block(flowId, this.block.id);
      const sameRedirect = this.sameRedirect == "on";
      const split = {
        A: parseInt(this.exitA.toString()) / 100,
        B: parseInt(this.exitB.toString()) / 100
      };
      if(this.block) await block.update({
        name: params?.name,
        description: params?.description || "",
        same_redirect: sameRedirect,
        split
      } as ISplit);
      this.block.same_redirect = sameRedirect;
      this.block.split = split;
      this.$emit("update:data", this.block);
    }
  },
});
