
import { defineComponent } from "vue";
import { MdCard } from "@/components/md/MdCard";
import { MdTooltip } from "@/components/md/MdTooltip";
import { MdButton } from "@/components/md/MdButton";
import { MdInputField } from "@/components/md/MdInput";
import FlowStepsList, { IStep } from "@/components/bot/FlowStepsList.vue";

export default defineComponent({
  name: "FlowPanelBlockElementsList",
  components: {
    MdTooltip,
    MdButton,
    MdInputField,
    MdCard,
    FlowStepsList
  },
  emits: ["click", "close"],
  data() {
    return {
      query: "",
      scroll: false,
      elements: [{
        name: "Basis",
        type: "category",
        isCategory: true,
        category: "basic",
        _expanded: true
      }, {
        name: "Textnachricht",
        type: "text",
        icon: "chat",
        category: "basic",
        description: "Sendet eine Textnachricht. Kann Platzhalter enthalten, um personalisiert zu werden."
      }, {
        name: "Buttons",
        type: "buttons",
        icon: "call_split",
        category: "basic",
        description: "Sendet ein oder mehrere Buttons."
      }, {
        name: "Bild",
        type: "image",
        icon: "image",
        category: "basic",
        description: "Sendet ein Bild oder eine Grafik."
      }, {
        name: "Video",
        type: "video",
        icon: "movie_creation",
        category: "basic",
        description: "Sendet ein Video. Es kann ein Thumbnail festgelegt werden. Nur verfügbar im Web, Facebook Messenger und Telegram."
      }, {
        name: "Audio",
        type: "audio",
        icon: "headset",
        category: "basic",
        description: "Sendet eine Audiodatei. Nur verfügbar im Web, Facebook Messenger und Telegram."
      }, {
        name: "Datei",
        type: "file",
        icon: "insert_drive_file",
        category: "basic",
        description: "Sendet eine beliebige Datei. Nur verfügbar im Web, Facebook Messenger und Telegram."
      }, {
        name: "Karussell",
        type: "carousel",
        icon: "view_carousel",
        category: "basic",
        description: "Sendet ein Karussell mit bis zu 10 Karten. Nur verfügbar im Web, Facebook Messenger und Instagram."
      }, {
        name: "Aktionen",
        type: "category",
        isCategory: true,
        category: "actions",
        _expanded: true
      }, {
        name: "E-Mail",
        type: "email",
        icon: "email",
        category: "actions",
        description: "Sendet eine E-Mail an die angegebene Adresse inkl. Chatverlauf und Link zum Posteingang. Nur geeignet für das Senden an Mitarbeiter."
      }, {
        name: "Übergabe an Mitarbeiter",
        type: "human_takeover",
        icon: "emoji_people",
        category: "actions",
        description: "Der Chatbot übergibt die Unterhaltung an einen Mitarbeiter. Mitarbeiter werden daraufhin benachrichtigt und können die Unterhatung übernehmen."
      }, {
        name: "Chat als ungelesen markieren",
        type: "unread",
        icon: "bookmark",
        category: "actions"
      }, {
        name: "Variable festlegen",
        type: "set_variable",
        icon: "contacts",
        category: "actions",
        description: "Setzt den Wert einer Variable."
      }, {
        name: "Marketing",
        type: "category",
        isCategory: true,
        category: "marketing",
        _expanded: true
      }, {
        name: "Zu Zielgruppe hinzufügen",
        type: "audience_add",
        icon: "person_add",
        category: "marketing",
      }, {
        name: "Aus Zielgruppe entfernen",
        type: "audience_remove",
        icon: "person_remove",
        category: "marketing",
      }, {
        name: "GTM Ereignis",
        type: "gtm_event",
        icon: "query_stats",
        category: "marketing",
        description: "Löst ein Google Tag Manager Ereignis aus. Nur im Web verfügbar."
      }] as IStep[]
    }
  },
  methods: {
    onScroll(y: number) {
      this.scroll = y > 0;
    }
  }
});
