
import { defineComponent, ref } from "vue";
import { useStore } from "@/store";
import { VueFlow, Elements, Background, ConnectionMode, FlowEvents, addEdge, removeElements, isNode, Position, FlowInstance, Edge, GraphNode, Connection, Node } from "@braks/vue-flow";
import dagre from 'dagre'
import FlowPanel, { IFlowPanel } from "@/components/bot/FlowPanel.vue";
import FlowMenu from "@/components/bot/FlowMenu.vue";
import FlowPanelTransition from "@/components/bot/FlowPanelTransition.vue";
import FlowNodeEntry from "@/components/bot/FlowNodeEntry.vue";
import FlowNodeBlock from "@/components/bot/FlowNodeBlock.vue";
import FlowNodeFlow from "@/components/bot/FlowNodeFlow.vue";
import FlowNodeIntent from "@/components/bot/FlowNodeIntent.vue";
import FlowNodeCondition from "@/components/bot/FlowNodeCondition.vue";
import FlowNodeDelay from "@/components/bot/FlowNodeDelay.vue";
import FlowNodeSplit from "@/components/bot/FlowNodeSplit.vue";
import FlowNodeOutlet from "@/components/bot/FlowNodeOutlet.vue";
import FlowNodeCode from "@/components/bot/FlowNodeCode.vue";
import FlowNodeLoader from "@/components/bot/FlowNodeLoader.vue";
import FlowNodeNote from "@/components/bot/FlowNodeNote.vue";
import FlowStepsSidebar from "@/components/bot/FlowStepsSidebar.vue";
import FlowLoader from "@/components/bot/FlowLoader.vue";
import "@/assets/scss/flow.scss";
import { IMenuItem, TransitionMdMenu } from "@/components/md/MdMenu";
import { collection, getDocs, getFirestore } from "@firebase/firestore";
import { BotStore } from "@/store/constants";
import { IBlock, IButtonsElement, ICondition, IDelay, IFlow, ISplit, IStepFlow } from "@chatcaptain/types/chatbot";
import { MdFab } from "@/components/md/MdFab";
import { MdTooltip } from "@/components/md/MdTooltip";
import { MdButton } from "@/components/md/MdButton";
import { getEdges, removeEdgeBySource, toEdge, connectSplit, connectBlock, connectDelay, connectStep, connectCondition, connectFlow } from "@/utils/FlowEdges";
import { Block } from "@/classes/Block";
import { createFlowStep } from "@/utils/FlowNodes";
import { TransitionFade } from "@/components/cc/Transition";

export default defineComponent({
  name: "Flow",
  components: {
    VueFlow,
    Background,
    FlowPanel,
    FlowNodeEntry,
    FlowNodeBlock,
    FlowNodeFlow,
    FlowNodeIntent,
    FlowNodeCondition,
    FlowNodeDelay,
    FlowNodeSplit,
    FlowNodeOutlet,
    FlowNodeCode,
    FlowNodeLoader,
    FlowNodeNote,
    FlowPanelTransition,
    TransitionMdMenu,
    FlowMenu,
    FlowStepsSidebar,
    MdFab,
    MdTooltip,
    MdButton,
    FlowLoader,
    TransitionFade
  },
  setup() {
    const store = useStore();
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    return { store, dagreGraph };
  },
  data() {
    return {
      isLoading: true,
      nodeTypes: ["entry", "code", "block", "flow", "intent", "condition", "delay", "split", "outlet", "loader", "note"],
      edgeTypes: ["flow"],
      targetTypes: ["block", "code", "flow", "condition", "delay", "split", "outlet"],
      connectionLineStyle: { stroke: '#ddd' },
      ConnectionMode,
      flow: {} as FlowInstance,
      panelVisible: false,
      menuVisible: false,
      buttonConnectMenuVisible: false,
      menuItems: [] as IMenuItem[],
      buttonConnectMenuItems: [] as IMenuItem[],
      menuValue: "",
      menuX: 0,
      menuY: 0,
      targetEdge: null as Edge | null,
      blocks: [] as IBlock[],
      nodes: [] as Elements,
      stepsSidebarVisible: true,
      zoom: 1,
      targetNode: {} as GraphNode<IBlock>,
      targetEdgeParams: null as Connection | null,
      mousePosition: {
        x: 0,
        y: 0
      },
      panelLarge: false
    }
  },
  mounted() {
    window.addEventListener("mousemove", this.onMouseMove, false);
    this.isLoading = true;
    this.loadBlocks();
  },
  unmounted() {
    window.removeEventListener("mousemove", this.onMouseMove, false);
  },
  computed: {
    flowId(): string {
      return this.$route.params?.flowId as string;
    },
    zoomLevel(): number {
      return Math.round(this.zoom * 100);
    },
    language(): string {
      return this.store.getters[BotStore.Getters.GET_LANGUAGE];
    },
    parents(): IFlow[] {
      const flows: IFlow[] = [];
      const flow = this.store.getters[BotStore.Getters.GET_FLOW](this.flowId);
      if(!flow) return flows;
      let currentFlow: IFlow | undefined = flow;
      while(currentFlow && currentFlow.parent) {
        currentFlow = this.store.getters[BotStore.Getters.GET_FLOW](currentFlow.parent.flow_id);
        if(currentFlow && currentFlow != flow) flows.unshift(currentFlow);
      }
      flows.push(flow);
      return flows;
    }
  },
  watch: {
    targetNode(val: GraphNode<IBlock>, valBefore: GraphNode<IBlock>) {
      if(!valBefore || val.id !== valBefore.id) return;
      const newData = val.data as IBlock, oldData = valBefore.data as IBlock;
      for(let i = 0; i < 10; i++){
        if(newData.id) removeEdgeBySource(this.nodes, newData.id);
      }
      connectStep(newData, this.nodes);
      const index = this.nodes.findIndex(node => node.id == val.id);
      if(index || index === 0) this.nodes[index] = val;
    }
  },
  methods: {
    onMouseMove(event: MouseEvent) {
      this.mousePosition = {
        x: event.pageX,
        y: event.pageY
      };
    },
    async loadBlocks() {
      this.blocks = [];
      const docs = await getDocs(collection(getFirestore(), "dialogs/" + this.store.getters[BotStore.Getters.GET_ID] + "/flows/" + this.flowId + "/blocks"));
      docs.forEach(doc => {
        this.blocks.push({
          id: doc.id,
          ...doc.data() as IBlock
        } as IBlock);
      });
      // Create Flow elements
      if(this.blocks) this.blocks.forEach(block => {
        const types = {
          elements: "block",
          undefined: "block",
          null: "block",
          flow: "flow",
          code: "code",
          intent: "intent",
          condition: "condition",
          delay: "delay",
          split: "split",
          outlet: "outlet",
          note: "note"
        }
        let type = types[block?.type || "elements"];
        if(block.id == "entry") type = "entry";
        this.nodes.push({
          id: block?.id || Date.now().toString(),
          type: type,
          position: {
            x: typeof block.grid.x == "string" ? parseInt((block.grid.x as string).replace('px', '')) - 2000 : block.grid.x,
            y: typeof block.grid.y == "string" ? parseInt((block.grid.y as string).replace('px', '')) : block.grid.y
          },
          dragHandle: ".node-content",
          data: block
        } as GraphNode<IBlock>);
      });
      const edges = getEdges(this.blocks);
      this.nodes.push(...edges);
      if(this.flow && this.flow.fitView) setTimeout(() => {
        this.flow.fitView({
          padding: 10,
          minZoom: .75,
          nodes: ["entry"],
          offset: {
            x: -150
          }
        });
        this.isLoading = false;
      }, 1);
    },
    onFlowLoad(flowInstance: FlowInstance) {
      this.flow = flowInstance;
      this.flow.fitView({
        transitionDuration: 500,
        padding: 10,
        minZoom: .75,
        nodes: ["entry"],
        offset: {
          x: -150
        }
      });
    },
    onFlowMove(event: FlowEvents["move"]) {
      if(event) this.zoom = event.zoom;
    },
    onDragOver(event: DragEvent) {
      event.preventDefault();
      if(event.dataTransfer) event.dataTransfer.dropEffect = "move";
    },
    async onDrop(event: DragEvent) {
      if(this.flow){
        const type = event.dataTransfer?.getData("application/flow");
        if(!type) return;
        const position = this.flow.project({
          x: event.clientX - 300,
          y: event.clientY - 90
        });
        const loader = {
          id: "loader",
          type: "loader",
          position,
          draggable: false
        } as Node;
        this.nodes.push(loader);
        const result = await createFlowStep(this.flowId, type, position);
        this.nodes = removeElements([loader], this.nodes);
        const node = {
          id: result.id,
          type: type == "elements" ? "block" : type,
          position,
          data: result,
        } as Node;
        this.nodes.push(node);
      }
    },
    async onConnect(params: FlowEvents['connect']) {
      if(params.source == params.target) return;
      const source = this.nodes.filter(node => node.data && node.data.id == params.source)[0];
      switch (source.type) {
        case "entry":
        case "intent": {
          this.nodes = removeEdgeBySource(this.nodes, params.source);
          addEdge(toEdge(params.source, params.target), this.nodes);
          (source.data as IBlock).start = this.flowId + "/" + params.target;
          await new Block(this.flowId, params.source).update({
            start: (source.data as IBlock).start
          });
          break;
        }
        case "delay": {
          this.nodes = removeEdgeBySource(this.nodes, params.source);
          const node = this.nodes.find(el => el.id == params.source);
          const data = (node?.data as IDelay);
          data.redirect = this.flowId + "/" + params.target;
          connectDelay(data, this.nodes);
          await new Block(this.flowId, params.source).update({
            redirect: data.redirect
          });
          break;
        }
        case "block":
        case undefined:
        case null:
        case "elements": {
          const block = source.data as IBlock;
          if(block.elements) {
            this.buttonConnectMenuItems = [];
            const buttonElement = block.elements.filter(el => el.type == "buttons");
            if(buttonElement.length > 0){
              const buttons = buttonElement[0].content as IButtonsElement["content"];
              // const labels = buttons.map(button => );
              buttons.forEach((button, index) => {
                if(button.type == "block") this.buttonConnectMenuItems.push({
                  label: typeof button.name == "string" ? button.name : button.name[this.language],
                  value: index,
                  icon: (button.value && button.value.trim().length > 0) ? "link" : "arrow_forward"
                });
              });
            } 
            if(block.user_input && block.user_input != null) {
              this.buttonConnectMenuItems.push({
                label: "Nutzereingabe",
                value: "user_input",
                icon: "keyboard"
              });
            }
            if(this.buttonConnectMenuItems.length > 0){
              this.menuX = this.mousePosition.x;
              this.menuY = this.mousePosition.y;
              // TODO fix so that the menu can't be outside the viewport
              this.buttonConnectMenuVisible = true;
              this.targetEdgeParams = params;
            } else {
              this.nodes = removeEdgeBySource(this.nodes, params.source);
              addEdge(toEdge(params.source, params.target), this.nodes);
              await new Block(this.flowId, params.source).update({
                redirect: this.flowId + "/" + params.target
              });
            }
          }
          break;
        }
        case "split": {
          // redirects
          const split = source.data as ISplit;
          this.buttonConnectMenuItems = [{
            label: "Ausagang A (" + Math.round(split?.split.A * 100) + " %)",
            value: "split_A",
            icon: split?.redirects?.A ? "link" : "arrow_forward"
          }, {
            label: "Ausagang B (" + Math.round(split?.split.B * 100) + " %)",
            value: "split_B",
            icon: split?.redirects?.B ? "link" : "arrow_forward"
          }];
          this.menuX = this.mousePosition.x;
          this.menuY = this.mousePosition.y;
          // TODO fix so that the menu can't be outside the viewport
          this.buttonConnectMenuVisible = true;
          this.targetEdgeParams = params;
          break;
        }
        case "condition": {
          const condition = source.data as ICondition;
          this.buttonConnectMenuItems = [{
            label: "Wahr",
            value: "condition_T",
            icon: condition.redirects.T ? "link" : "arrow_forward"
          }, {
            label: "Falsch",
            value: "condition_F",
            icon: condition.redirects.F ? "link" : "arrow_forward"
          }];
          this.menuX = this.mousePosition.x;
          this.menuY = this.mousePosition.y;
          // TODO fix so that the menu can't be outside the viewport
          this.buttonConnectMenuVisible = true;
          this.targetEdgeParams = params;
          break;
        }
        case "flow": {
          const flow = source.data as IStepFlow;
          this.buttonConnectMenuItems = [];
          if(flow.outlets) Object.keys(flow.outlets).forEach(key => {
            const outlet = flow.outlets[key];
            this.buttonConnectMenuItems.push({
              label: outlet.name,
              value: "outlet_" + key,
              icon: outlet.connected ? "link" : "arrow_forward"
            });
          });
          this.menuX = this.mousePosition.x;
          this.menuY = this.mousePosition.y;
          this.buttonConnectMenuVisible = true;
          this.targetEdgeParams = params;
          break;
        }
      }
    },
    async onButtonConnectItemClick(value: number | string) {
      if(this.targetEdgeParams){
        const source = this.nodes.filter(node => node.data && node.data.id == this.targetEdgeParams?.source)[0];
        const block = source.data as IBlock;
        if(value == "user_input"){
          this.nodes = removeEdgeBySource(this.nodes, this.targetEdgeParams.source, value);
          block.user_input = {
            target: this.flowId + "/" + this.targetEdgeParams.target
          };
          connectBlock(block, this.nodes);
          await new Block(this.flowId, this.targetEdgeParams.source).update({
            user_input: block.user_input
          });
        } else if(value.toString().startsWith("split_")) {
          const split = block as ISplit;
          const exit = value.toString().replace("split_", "");
          if(!split.redirects) split.redirects = {A: null, B: null};
          split.redirects[exit] = this.flowId + "/" + this.targetEdgeParams.target;
          this.nodes = removeEdgeBySource(this.nodes, this.targetEdgeParams.source, exit);
          connectSplit(split, this.nodes);
          await new Block(this.flowId, this.targetEdgeParams.source).update({
            redirects: split.redirects
          });
        } else if(value.toString().startsWith("condition_")) {
          const condition = block as ICondition;
          const exit = value.toString().replace("condition_", "");
          if(!condition.redirects) condition.redirects = {T: null, F: null};
          condition.redirects[exit] = this.flowId + "/" + this.targetEdgeParams.target;
          this.nodes = removeEdgeBySource(this.nodes, this.targetEdgeParams.source, exit);
          connectCondition(condition, this.nodes);
          await new Block(this.flowId, this.targetEdgeParams.source).update({
            redirects: condition.redirects
          });
        } else if(value.toString().startsWith("outlet_")) {
          const flow = block as IStepFlow;
          const outletId = value.toString().replace("outlet_", "");
          if(!flow.outlets) flow.outlets = {};
          if(!flow.outlets[outletId]) flow.outlets[outletId] = {connected: null, name: ""};
          flow.outlets[outletId].connected = this.flowId + "/" + this.targetEdgeParams.target;
          this.nodes = removeEdgeBySource(this.nodes, this.targetEdgeParams.source, outletId);
          connectFlow(flow, this.nodes);
          await new Block(this.flowId, this.targetEdgeParams.source).update({
            outlets: flow.outlets
          });
        } else if(block.elements) {
          const buttonElementIndex = block.elements.findIndex(el => el.type == "buttons");
          const buttonElement = block.elements[buttonElementIndex] as IButtonsElement;
          buttonElement.content[value].value = this.flowId + "/" + this.targetEdgeParams.target;
          block.elements[buttonElementIndex] = buttonElement;
          source.data = block;
          // Check if there is another edge for this button and delete it
          this.nodes = removeEdgeBySource(this.nodes, this.targetEdgeParams.source, value);
          connectBlock(block, this.nodes);
          await new Block(this.flowId, this.targetEdgeParams.source).update({
            elements: block.elements
          });
        }
      }
      this.buttonConnectMenuVisible = false;
    },
    connectStart() {
      this.nodes.forEach(element => {
        if(this.targetTypes.includes(element.type || "")) {
          if(!element.data) element.data = {};
          element.data.showHandle = true;
        }
      });
    },
    connectEnd() {
      this.nodes.forEach(element => {
        if(this.targetTypes.includes(element.type || "")) {
          if(!element.data) element.data = {};
          element.data.showHandle = false;
        }
      });
    },
    onNodeClick(flowEvent: FlowEvents["nodeClick"]) {
      this.menuVisible = false;
      this.buttonConnectMenuVisible = false;
      const node = flowEvent.node;
      this.panelLarge = node.type == "condition" || node.type == "intent";
      this.targetNode = node;
      this.focusNode(node.id);
      this.panelVisible = true;
    },
    focusNode(nodeId: string) {
      const width = window.innerWidth;
      let transformX = 0;
      transformX = ((width - 1460) / 2 * -1) + (this.panelLarge ? 300 : 0);
      this.flow.fitView({
        nodes: [nodeId],
        padding: 0,
        transitionDuration: 400,
        maxZoom: 1.2,
        offset: {
          x: transformX,
          y: 0
        }
      });
    },
    onPaneClick() {
      this.menuVisible = false;
      this.buttonConnectMenuVisible = false;
      if(this.$refs.flowPanel) (this.$refs.flowPanel as IFlowPanel).close();
    },
    onLayout(direction: string) {
      const isHorizontal = direction === 'LR'
      this.dagreGraph.setGraph({ rankdir: direction })
      this.nodes.forEach((el) => {
        if (isNode(el)) {
          this.dagreGraph.setNode(el.id, { width: 200, height: 200 })
        } else {
          this.dagreGraph.setEdge(el.source, el.target)
        }
      })
      dagre.layout(this.dagreGraph)
      this.nodes.forEach((el) => {
        if (isNode(el)) {
          const nodeWithPosition = this.dagreGraph.node(el.id)
          el.targetPosition = isHorizontal ? Position.Left : Position.Top
          el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom
          el.position = { x: nodeWithPosition.x, y: nodeWithPosition.y }
        }
      })
    },
    onEdgeClick(params: FlowEvents["edgeClick"]) {
      const event = params.event;
      const edge = params.edge;
      this.targetEdge = edge;
      this.menuItems = [{
        label: "Gehe zu Block",
        value: "focus",
        icon: "arrow_forward"
      }, {
        label: "Verbindung löschen",
        value: "delete_edge",
        icon: "delete_forever"
      }] as IMenuItem[];
      this.menuX = event.x;
      this.menuY = event.y;
      this.menuVisible = true;
    },
    async onMenuItemClick(itemValue: string) {
      this.menuVisible = false;
      if(this.targetEdge) switch (itemValue) {
        case "focus": {
          this.focusNode(this.targetEdge.target);
          break;
        }
        case "delete_edge": {
          if(!this.targetEdge) return;
          this.nodes = removeElements([this.targetEdge], this.nodes);
          const sourceIndex = this.nodes.findIndex(node => node.data && node.data.id == this.targetEdge?.source);
          const source = this.nodes[sourceIndex];
          const block = source.data as IBlock;
          if(block.elements) {
            if(this.targetEdge.sourceHandle && this.targetEdge.sourceHandle != "default") {
              if(this.targetEdge.sourceHandle == "user_input") {
                await new Block(this.flowId, this.targetEdge.source).update({
                  user_input: {target: null}
                });
              } else {
                const buttonElementIndex = block.elements.findIndex(el => el.type == "buttons");
                (block.elements[buttonElementIndex] as IButtonsElement).content[parseInt(this.targetEdge.sourceHandle)].value = "";
                source.data = block;
                this.nodes[sourceIndex] = source;
                await new Block(this.flowId, this.targetEdge.source).update({
                  elements: block.elements
                });
              }
            } else {
              await new Block(this.flowId, this.targetEdge.source).update({
                redirect: undefined
              });
            }
          } else {
            switch (block.type) {
              case "entry":
              case "intent":
                await new Block(this.flowId, this.targetEdge.source).update({
                  start: undefined
                });
                break;
              case "split":
              case "delay":
                await new Block(this.flowId, this.targetEdge.source).update({
                  redirect: undefined
                });
                break;
              case "condition":
                await new Block(this.flowId, this.targetEdge.source).update({
                  ['redirects.' + this.targetEdge.sourceHandle]: null
                });
                break;
              case "flow":
                await new Block(this.flowId, this.targetEdge.source).update({
                  ['outlets.' + this.targetEdge.sourceHandle+'.connected']: null
                });
                break;
            }
          }
          break;
        }
      }
    },
    async onNodeDragStop(params: FlowEvents["nodeDragStop"]) {
      const node = params.node;
      const id = (node.data as IBlock).id;
      if(id) await new Block(this.flowId, id).updatePosition(node.position.x, node.position.y);
    },
    onTargetNodeDelete() {
      if(!this.targetNode) return;
      this.nodes = removeElements([this.targetNode], this.nodes);
    },
    goToFlow(flowId: string) {
      this.$router.push({
        name: "BotFlow",
        params: {
          flowId: flowId
        }
      });
    },
  },
});
