import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-428f249a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loader-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MdProgressIndicatorCircular = _resolveComponent("MdProgressIndicatorCircular")!
  const _component_flow_node_wrapper = _resolveComponent("flow-node-wrapper")!

  return (_openBlock(), _createBlock(_component_flow_node_wrapper, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MdProgressIndicatorCircular, { size: "large" })
      ])
    ]),
    _: 1
  }))
}