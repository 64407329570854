
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { BotStore, RootStore } from "@/store/constants";
import { deleteFaq } from "@/api/bot/faqs";
import { IIntent } from "@chatcaptain/types/chatbot";

export default defineComponent({
  name: "FaqDeleteModal",
  mixins: [MdModalComponentMixin],
  components: { 
    MdModal,
    MdButton,
  },
  props: {
    faq: {
      type: Object as PropType<IIntent>,
      required: true,
    },
  },
  setup() {
    return {
      store: useStore()
    };
  },
  methods: {
    async deleteFaqPair() {
      this.setLoadingState(true);
      try {
        if(this.faq.id) await deleteFaq(this.faq.id);
        this.store.dispatch(BotStore.Actions.LOAD_FAQS);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "FAQ-Paar wurde gelöscht",
        });
      } catch(err) {
        console.error("error: ", err);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "FAQ-Paar konnte nicht gelöscht werden"
        });
      }
      this.setLoadingState(false);
      this.close();
    }
  },
});
