
import { defineComponent } from "vue";
import { MdCard } from "@/components/md/MdCard";
import { MdTooltip } from "@/components/md/MdTooltip";
import { MdButton } from "@/components/md/MdButton";
import { MdInputField } from "@/components/md/MdInput";
import FlowStepsList, { IStep } from "@/components/bot/FlowStepsList.vue";

export default defineComponent({
  name: "FlowSidebar",
  components: {
    MdCard,
    MdTooltip,
    MdButton,
    MdInputField,
    FlowStepsList
  },
  props: {
    subFlow: Boolean
  },
  emits: ["minimize"],
  data() {
    return {
      query: "",
      scroll: false,
      allSteps: [{
        name: "Häufig verwendet",
        type: "category",
        isCategory: true,
        category: "popular",
        _expanded: true
      }, {
        name: "Block",
        type: "elements",
        icon: "dashboard",
        category: "popular",
        description: "Kann unterschiedliche Elemente beeinhalten"
      }, {
        name: "Intention",
        type: "intent",
        icon: "textsms",
        category: "popular",
        description: "Reagiert auf Texteingaben von Nutzern."
      }, {
        name: "Notiz",
        type: "note",
        icon: "sticky_note_2",
        category: "popular",
        description: "Füge eine Notiz dirket im Bot Builder hinzu. Diese ist für Nutzer nicht sichtbar."
      }, {
        name: "Ablaufkontrolle",
        type: "category",
        isCategory: true,
        category: "flow_control",
        _expanded: true
      }, {
        name: "Bedingung",
        type: "condition",
        icon: "filter_alt",
        category: "flow_control",
      }, {
        name: "Verzögerung",
        type: "delay",
        icon: "schedule",
        category: "flow_control",
        description: "Verzögert den Ablauf bis zum nächsten Schritt."
      }, {
        name: "A/B-Split",
        type: "split",
        icon: "call_split",
        category: "flow_control",
        description: "Weißt Nutzer zufällig einer Variante zu."
      }, {
        name: "Erweiterte Funktionen",
        type: "category",
        isCategory: true,
        category: "extended",
        _expanded: true
      }, {
        name: "Ablauf",
        type: "flow",
        icon: "merge_type",
        category: "extended",
        description: "Untergeordneter Ablauf, um eine bessere Übersicht zu schaffen."
      }, {
        name: "Entwickler",
        type: "category",
        isCategory: true,
        category: "developer",
        _expanded: true
      }, {
        name: "JavaScript Code",
        type: "code",
        icon: "code",
        category: "developer",
        description: "JavaScript Code direkt in ChatCaptain ausführen."
      }, {
        name: "JSON API",
        type: "json_api",
        icon: "api",
        category: "developer",
        description: "JSON API aufrufen und Ergebnis in ChatCaptain verwenden."
      }] as IStep[]
    }
  },
  mounted() {
    if(this.subFlow === true) {
      // 4 is the current index to insert the step "Ausstieg" on subflows
      this.allSteps.splice(4, 0, {
        name: "Ausstieg",
        type: "outlet",
        icon: "logout",
        category: "flow_control",
      });
    }
  },
  methods: {
    onScroll(y: number) {
      this.scroll = y > 0;
    }
  }
});
