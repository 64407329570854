
import { defineComponent, PropType } from "vue";
import { IBlock } from "@chatcaptain/types/chatbot";
import FlowPanelContentMixin, { ISaveParams } from "@/components/bot/FlowPanelContentMixin";
import { Block } from "@/classes/Block";
import { updateOutletName } from "@/api/bot/flow/flow";

export default defineComponent({
  name: "FlowPanelContentOutlet",
  mixins: [FlowPanelContentMixin],
  props: {
    data: {
      type: Object as PropType<IBlock>,
      required: true
    }
  },
  data() {
    return {
      block: {} as IBlock,
      index: 0
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(val: IBlock) {
        this.block = val;
      }
    },
  },
  methods: {
    async save(flowId: string, params?: ISaveParams) {
      if(!this.block.id) return;
      const block = new Block(flowId, this.block.id);
      if(this.block) await block.update({
        name: params?.name,
        description: params?.description || ""
      });
      if(params) await updateOutletName(flowId, this.block.id, params.name);
      this.$emit("update:data", this.block);
    }
  },
});
