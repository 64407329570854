
import { defineComponent, PropType } from "vue";
import { IBlock, IExample, IIntent, ILanguageValue, IStepIntent } from "@chatcaptain/types/chatbot";
import FlowPanelContentMixin, { ISaveParams } from "@/components/bot/FlowPanelContentMixin";
import { Block } from "@/classes/Block";
import { IRow, IRowBadge, IRowButton, MdTable, MdTableColumn } from "@/components/md/MdTable";
import { EmptyHint } from "@/components/cc/EmptyHint";
import { useStore } from "@/store";
import { BotStore, RootStore } from "@/store/constants";
import { MdHint } from "@/components/md/MdHint";
import { MdInputField } from "@/components/md/MdInput";
import { createExample, deleteExample, getExamples } from "@/api/bot/examples";
import { Timestamp } from "firebase/firestore";
import { MdCard } from "@/components/md/MdCard";
import { MdCheckbox } from "@/components/md/MdCheckbox";
import { getDefaultIntelligentEnquireQuestion, getIntent, updateIntent } from "@/api/bot/intents";

export default defineComponent({
  name: "FlowPanelContentIntent",
  components: {
    MdTable,
    MdTableColumn,
    EmptyHint,
    MdHint,
    MdInputField,
    MdCard,
    MdCheckbox
  },
  mixins: [FlowPanelContentMixin],
  props: {
    data: {
      type: Object as PropType<IBlock>,
      required: true
    }
  },
  setup() {
    return {
      store: useStore()
    }
  },
  data() {
    return {
      block: {} as IStepIntent,
      index: 0,
      isLoading: true,
      examples: [] as IExample[],
      example: "",
      advancedSettingsOpen: false,
      intelligentEnquireQuestion: {} as ILanguageValue<string>,
      intelligentEnquireActive: true,
      intent: {} as IIntent,
      deleteButton: {
        icon: "delete",
        tooltip: "Löschen"
      } as IRowButton
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(val: IBlock) {
        this.block = val as IStepIntent;
        this.loadIntent();
        this.loadExamples();
      }
    },
    intent: {
      immediate: true,
      async handler(val: IIntent) {
        if(!val || !val.id) return;
        if(!val.intelligent_enquire) val.intelligent_enquire = {disabled: false, name: val.name};
        if(val.intelligent_enquire) this.intelligentEnquireActive = !val.intelligent_enquire.disabled;
        if(!val.intelligent_enquire || !val.intelligent_enquire.full_question){ 
          const question = await getDefaultIntelligentEnquireQuestion();
          if(question) {
            const name = this.block.name;
            if(typeof question == "string"){
              this.intelligentEnquireQuestion = {[this.defaultLanguage]: question.replace("%intent%", name)};
            } else {
              Object.keys(question).forEach(lang => {
                question[lang] = question[lang].replace("%intent%", name);
              });
              this.intelligentEnquireQuestion = question;
            }
          }
        } else {
          this.intelligentEnquireQuestion = (typeof val.intelligent_enquire.full_question == "string") ? {[this.defaultLanguage]: val.intelligent_enquire.full_question} : val.intelligent_enquire.full_question;
        }
      }
    },
    language() {
      this.loadExamples();
    }
  },
  computed: {
    rows(): IRow[] {
      const rows: IRow[] = [];
      this.examples.forEach(example => {
        rows.push({
          id: example.id || null,
          text: example.text,
          score: {
            state: example.prediction_score ? (example.prediction_score >= 0.9 ? "success" : (example.prediction_score >= 0.7 ? "warning" : "error")) : "neutral",
            text: example.prediction_score ? (Math.round(example.prediction_score * 100) + " %") : "N/A"
          } as IRowBadge,
          delete: example.id ? this.deleteButton : null,
          _loading: !example.id
        });
      });
      return rows;
    },
    intentId(): string {
      return this.block.intent_id || "";
    },
    language(): string {
      return this.store.getters[BotStore.Getters.GET_LANGUAGE];
    },
    languageName(): string {
      return this.store.getters[BotStore.Getters.GET_LANGUAGE_NAME](this.language);
    },
    defaultLanguage(): string {
      return this.store.getters[BotStore.Getters.GET_DEFAULT_LANGUAGE];
    }
  },
  methods: {
    async loadIntent() {
      const intent = await getIntent(this.intentId);
      if(!intent) {
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Die Intention konnte nicht geladen werden"
        });
        return;
      }
      this.intent = intent;
    },
    async loadExamples() {
      this.isLoading = true;
      this.examples = [];
      if(this.data.id) this.examples = await getExamples(this.store.getters[BotStore.Getters.GET_ID], this.intentId, this.store.getters[BotStore.Getters.GET_LANGUAGE]);
      this.isLoading = false;
    },
    getFormattedDate(date: Date): string {
      return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
    },
    exampleKeyDown(event: KeyboardEvent){
      if(event.key == "Enter") this.addExample();
    },
    async addExample() {
      if(this.example && this.example.trim().length > 0){
        const language = this.store.getters[BotStore.Getters.GET_LANGUAGE];
        this.example = this.example.trim();
        const text = this.example;
        this.examples.unshift({
          created: Timestamp.fromDate(new Date()),
          updated: Timestamp.fromDate(new Date()),
          text: text,
          language,
          intent_id: this.intentId
        });
        this.example = "";
        const chatbotId = this.store.getters[BotStore.Getters.GET_ID];
        const id = await createExample(chatbotId, text, [], language, this.intentId);
        this.examples[0].id = id;
      }
    },
    async handleTableButtonClick(column: string, row: IRow, index: number) {
      const exampleId = row.id as string;
      const exampleIndex = this.examples.findIndex(example => example.id == exampleId);
      delete this.examples[exampleIndex].id;
      await deleteExample(this.store.getters[BotStore.Getters.GET_ID], exampleId);
      this.examples.splice(exampleIndex, 1);
    },
    async save(flowId: string, params?: ISaveParams) {
      if(!this.block.id) return;
      const block = new Block(flowId, this.block.id);
      if(this.intent) {
        this.intent.intelligent_enquire = {
          full_question: this.intelligentEnquireQuestion,
          disabled: !this.intelligentEnquireActive,
          name: params?.name || this.intent.name || ""
        }
        await updateIntent(this.intentId, params?.name || this.intent.name, this.intent.intelligent_enquire);
      }
      if(this.block) await block.update({
        name: params?.name,
        description: params?.description || ""
      });
      this.$emit("update:data", this.block);
    }
  },
});
