
import { defineComponent } from "vue";
import { MdProgressIndicatorCircular } from "@/components/md/MdProgressIndicator";

export default defineComponent({
  name: "FlowLoader",
  components: {
    MdProgressIndicatorCircular
  }
});
