import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-952c8682"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "element-header" }
const _hoisted_2 = {
  key: 0,
  class: "material-icons-round"
}
const _hoisted_3 = { class: "buttons" }
const _hoisted_4 = { class: "element-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_tooltip = _resolveComponent("md-tooltip")!
  const _component_md_card = _resolveComponent("md-card")!

  return (_openBlock(), _createBlock(_component_md_card, {
    class: "element-wrapper",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onHover(true))),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onHover(false)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", {
          class: _normalizeClass({ icon: _ctx.icon })
        }, [
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("i", _hoisted_2, _toDisplayString(_ctx.icon), 1))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.name) + " ", 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_md_tooltip, {
              position: "top",
              text: "Verschieben"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_md_button, {
                  variant: "icon",
                  icon: "drag_indicator",
                  grey: "",
                  class: "drag",
                  mode: "icon_small"
                })
              ]),
              _: 1
            }),
            _renderSlot(_ctx.$slots, "buttons", {}, undefined, true),
            _createVNode(_component_md_tooltip, {
              position: "top",
              text: "Löschen"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_md_button, {
                  variant: "icon",
                  icon: "delete_forever",
                  grey: "",
                  mode: "icon_small",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delete')))
                })
              ]),
              _: 1
            })
          ])
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _: 3
  }))
}