
import { defineComponent } from "vue";
import { IRow, IRowButton, MdTable, MdTableColumn } from "@/components/md/MdTable";
import { useStore } from "@/store";
import { EmptyHint } from "@/components/cc/EmptyHint";
import { MdButton } from "@/components/md/MdButton";
import { MdFabExtended } from "@/components/md/MdFab";

export default defineComponent({
  name: "Files",
  components: {
    MdTable,
    MdTableColumn,
    EmptyHint,
    MdButton,
    MdFabExtended
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      isLoading: true,
      deleteButton: {
        icon: "delete",
        tooltip: "Löschen"
      } as IRowButton
    }
  },
  mounted() {
    // this.store.dispatch(BotStore.Actions.LOAD_FAQS)
  },
  watch: {
    files() {
      this.isLoading = false;
    }
  },
  computed: {
    
  },
  methods: {
    
  },
});
