
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "MdAlert",
  props: {
    variant: {
      type: String as PropType<"info" | "neutral" | "warning" | "error" | "success">,
      default: "info"
    },
    icon: String
  }
});
