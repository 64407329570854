
import { defineComponent } from "vue";
import { IRow, IRowBadge, IRowButton, MdTable, MdTableColumn } from "@/components/md/MdTable";
import { useStore } from "@/store";
import { BotStore, RootStore } from "@/store/constants";
import { IExample, IIntent, IBlockElement, ITextElement, IButtonsElement, ILanguageValue } from "@chatcaptain/types/chatbot";
import { EmptyHint } from "@/components/cc/EmptyHint";
import { MdButton } from "@/components/md/MdButton";
import { createExample, deleteExample, getExamples } from "@/api/bot/examples";
import { MdInputField } from "@/components/md/MdInput";
import { MdHint } from "@/components/md/MdHint";
import { MdCard } from "@/components/md/MdCard";
import { MdCheckbox } from "@/components/md/MdCheckbox";
import { Timestamp } from "@firebase/firestore";
import { getDefaultIntelligentEnquireQuestion } from "@/api/bot/intents";
import { saveFaqIntent } from "@/api/bot/faqs";
import { MdAlert } from "@/components/md/MdAlert";
import { TransitionFade } from "@/components/cc/Transition";

interface IFaqButton {
  label: ILanguageValue<string>;
  value: string;
}

export default defineComponent({
  name: "Faq",
  components: {
    MdTable,
    MdTableColumn,
    EmptyHint,
    MdButton,
    MdInputField,
    MdHint,
    MdCard,
    MdCheckbox,
    MdAlert,
    TransitionFade
  },
  setup() {
    return {
      store: useStore()
    }
  },
  data() {
    return {
      name: "",
      description: "",
      example: "",
      isLoading: true,
      messages: [] as ILanguageValue<string>[],
      buttons: [] as IFaqButton[],
      advancedSettingsOpen: false,
      intelligentEnquireQuestion: {} as ILanguageValue<string>,
      intelligentEnquireActive: true,
      examples: [] as IExample[],
      deleteButton: {
        icon: "delete",
        tooltip: "Löschen"
      } as IRowButton,
      showTranslationDelayAlert: false
    }
  },
  async mounted() {
    this.loadExamples();
    this.name = this.faq?.name || "";
  },
  computed: {
    faqId(): string {
      return this.$route.params.faqId as string;
    },
    faq(): IIntent | undefined {
      return this.store.getters[BotStore.Getters.GET_FAQ](this.faqId);
    },
    rows(): IRow[] {
      const rows: IRow[] = [];
      this.examples.forEach(example => {
        rows.push({
          id: example.id || null,
          text: example.text,
          score: {
            state: example.prediction_score ? (example.prediction_score >= 0.9 ? "success" : (example.prediction_score >= 0.7 ? "warning" : "error")) : "neutral",
            text: example.prediction_score ? (Math.round(example.prediction_score * 100) + " %") : "N/A"
          } as IRowBadge,
          delete: example.id ? this.deleteButton : null,
          _loading: !example.id
        });
      });
      return rows;
    },
    language(): string {
      return this.store.getters[BotStore.Getters.GET_LANGUAGE];
    },
    languageName(): string {
      return this.store.getters[BotStore.Getters.GET_LANGUAGE_NAME](this.language);
    },
    defaultLanguage(): string {
      return this.store.getters[BotStore.Getters.GET_DEFAULT_LANGUAGE];
    }
  },
  watch: {
    faq: {
      immediate: true,
      async handler(val: IIntent | undefined) {
        if(!this.$route.params.faqId) return;
        if(!val && !this.isLoading) {
          this.$router.push({
            name: "BotFaqs"
          });
          this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
            state: "error",
            content: "FAQ-Paar nicht gefunden"
          });
          return;
        }
        if(!val) return;
        this.name = this.faq?.name || "";
        if(val.answers){
          const answers: IBlockElement[] = val.answers as IBlockElement[];
          answers.forEach((answer: IBlockElement) => {
            switch (answer.type) {
              case "text": {
                const message = (answer as ITextElement).content.message;
                this.messages.push((typeof message == "string") ? {[this.defaultLanguage]: message} : message);
                break;
              }
              case "buttons": {
                const buttonsMessage: IButtonsElement = answer as IButtonsElement;
                buttonsMessage.content.forEach(button => {
                  this.buttons.push({
                    label: (typeof button.name == "string") ? {[this.defaultLanguage]: button.name} : button.name,
                    value: button.value
                  });
                });
                break;
              }
            }
          });
        } else {
          this.messages.push({});
        }
        this.intelligentEnquireActive = !val.intelligent_enquire.disabled;
        if(!val.intelligent_enquire.full_question){ 
          const question = await getDefaultIntelligentEnquireQuestion();
          if(question) {
            const name = this.faq?.name || "INTENT";
            if(typeof question == "string"){
              this.intelligentEnquireQuestion = {[this.defaultLanguage]: question.replace("%intent%", name)};
            } else {
              Object.keys(question).forEach(lang => {
                question[lang] = question[lang].replace("%intent%", name);
              });
              this.intelligentEnquireQuestion = question;
            }
          }
        } else {
          this.intelligentEnquireQuestion = (typeof val.intelligent_enquire.full_question == "string") ? {[this.defaultLanguage]: val.intelligent_enquire.full_question} : val.intelligent_enquire.full_question;
        }
      }
    },
    language() {
      this.loadExamples();
    }
  },
  methods: {
    async loadExamples() {
      this.isLoading = true;
      this.examples = [];
      this.examples = await getExamples(this.store.getters[BotStore.Getters.GET_ID], this.faqId, this.store.getters[BotStore.Getters.GET_LANGUAGE]);
      this.isLoading = false;
    },
    getFormattedDate(date: Date): string {
      return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
    },
    exampleKeyDown(event: KeyboardEvent){
      if(event.key == "Enter") this.addExample(event.shiftKey);
    },
    async addExample(translate: boolean) {
      if(this.example && this.example.trim().length > 0){
        const language = this.store.getters[BotStore.Getters.GET_LANGUAGE];
        this.example = this.example.trim();
        const text = this.example;
        this.examples.unshift({
          created: Timestamp.fromDate(new Date()),
          updated: Timestamp.fromDate(new Date()),
          text: text,
          language,
          intent_id: this.faqId
        });
        this.example = "";
        const chatbotId = this.store.getters[BotStore.Getters.GET_ID];
        const id = await createExample(chatbotId, text, [], language, this.faqId, translate);
        this.examples[0].id = id;
        // TODO activate translation info box
        if(translate) this.showTranslationDelayAlert = true;
      }
    },
    async handleTableButtonClick(column: string, row: IRow, index: number) {
      const exampleId = row.id as string;
      const exampleIndex = this.examples.findIndex(example => example.id == exampleId);
      delete this.examples[exampleIndex].id;
      await deleteExample(this.store.getters[BotStore.Getters.GET_ID], exampleId);
      this.examples.splice(exampleIndex, 1);
    },
    async saveChanges() {
      if(this.messages.length <= 0){
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Du musst mindestens eine Nachricht hinzufügen"
        });
        return;
      }
      try {
        const elements: IBlockElement[] = [];
        const buttons: IButtonsElement["content"] = [];
        this.messages.forEach(message => {
          elements.push({
            type: "text",
            content: {
              message
            }
          });
        });
        this.buttons.forEach(button => {
          buttons.push({
            name: button.label,
            type: "url",
            value: button.value
          });
        });
        elements.push({
          type: "buttons",
          content: buttons
        });
        await saveFaqIntent(this.faqId, this.name, elements, {
          disabled: !this.intelligentEnquireActive,
          name: this.name,
          full_question: this.intelligentEnquireQuestion
        });
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Änderungen gespeichert"
        });
        this.$router.push({
          name: "BotFaqs"
        });
      } catch(err) {
        console.error('error: ', err);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Änderungen konnten nicht gespeichert werden"
        });
      }
    }
  },
});
